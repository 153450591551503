/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    strong: "strong",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {HrefLangManager, TableOfContents, SideBySide, ButtonCta} = _components;
  if (!ButtonCta) _missingMdxReference("ButtonCta", true);
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!SideBySide) _missingMdxReference("SideBySide", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/hearing-aids/as-seen-on-tv/'], ['en', 'https://www.hear.com/hearing-aids/as-seen-on-tv/'], ['en-US', 'https://www.hear.com/hearing-aids/as-seen-on-tv/'], ['en-CA', 'https://ca.hear.com/hearing-aids/as-seen-on-tv/']]
  }), "\n", React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "the-truth-behind-as-seen-on-tv-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-truth-behind-as-seen-on-tv-hearing-aids",
    "aria-label": "the truth behind as seen on tv hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The truth behind as seen on TV hearing aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "You are sitting at home, watching TV, when suddenly, a commercial advertisement flashes through your screen. The product is a hearing device that works as an amplifier by making sounds around you louder. Since you have hearing loss, you think to yourself, “this is exactly what I need!”. The aid seems legit, it has ", React.createElement(_components.a, {
    href: "/about-us/customer-voices",
    className: "c-md-a"
  }, "a lot positive customer reviews,"), " and the price is cheap! It even includes free shipping. Finally, a contact number appears and you quickly reach for your phone to call and place your order. But should you really do it? Is this just another one of those TV scams?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "A sound amplifier will certainly make things louder, but it will not improve your hearing the same way hearing aids do. This is because ", React.createElement(_components.a, {
    href: "/hearing-loss",
    className: "c-md-a"
  }, "hearing loss is a medical condition"), " that requires the help of a licensed professional. For example, when you can’t see well from far, a pair of non-prescription glasses from your local drug store is not going to correct your vision. Similarly, a sound amplifier may be helpful, but it will not make your hearing better. Below you will learn more about those as seen on TV hearing aids and why you should think twice before considering them."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-kind-of-devices-are-they",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-kind-of-devices-are-they",
    "aria-label": "what kind of devices are they permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What kind of devices are they?"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/as-seen-on-tv.png"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "As seen on TV hearing aids are not necessarily the assistive listening devices that you are probably used to seeing. One particular model is the MSA 30x, which is a device that makes sounds and noises louder. This amplifier resembles the older generation hearing aids that were beige and sat behind the ear. They can be purchased over the counter, and most stores like Sears carry them. Since they do not need to be fitted or customized to a person’s hearing loss, you can also find them for sale online at Amazon or ebay. Most, if not all, of the other as seen on TV hearing aids serve the same purpose: amplification. They will pick up all kinds of sounds and increase the volume to a higher level.")), "\n", React.createElement(ButtonCta, {
    copy: "Get the best hearing aid now",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "pros-and-cons-of-as-seen-on-tv-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#pros-and-cons-of-as-seen-on-tv-hearing-aids",
    "aria-label": "pros and cons of as seen on tv hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Pros and cons of as seen on TV hearing aids?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Some people feel content with an amplification system, while others do not experience the same sentiment. The reason being: hearing loss differs from person to person. The same way you wouldn’t share your prescription glasses with someone who has vision loss, you also wouldn’t share hearing aids with another person. To better understand what these amplifiers offer, here is a quick summary of the pros and cons of as seen on TV hearing aids."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Pros:")), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Cheap"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Rechargeable"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Does not require a fitting"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "One-size-fits-all"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Can be worn in either ear"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Cons:")), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Not programmed to your hearing loss"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Improper fitment"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Poor sound quality"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Feedback and loud whistling"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Not durable"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "All hearing aids are amplifiers but not all amplifiers are hearing aids. Understanding the differences between the two will help you see the importance of finding a good hearing loss solution."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearing-aids-vs-sound-amplifiers",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearing-aids-vs-sound-amplifiers",
    "aria-label": "hearing aids vs sound amplifiers permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hearing aids vs. sound amplifiers"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Some people who are looking for a quick fix, without having to spend too much money, settle for a sound amplifier. The idea is, if you can’t hear, all you need is an increase in volume. The louder the better. As seen on TV hearing aids are an affordable – yet temporary – solution to hearing loss. In the long run, however, an amplifier will not adequately address the root cause of your condition."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids",
    className: "c-md-a"
  }, "Hearing aids are medical devices designed to treat hearing loss."), " An audiologist or hearing care professional, therefore, needs to evaluate your hearing to recommend a suitable device. A good hearing instrument needs to do more than make everything louder. In fact, it needs to make things like voices and speech clearer. A common complaint among people with hearing difficulties is, they can hear, but can’t understand. This is because we use our brains to listen, not our ears. A hearing aid that is tailored to your needs by a hearing specialist will automatically locate the source of sound and amplify it, while cancelling out background noise. ", React.createElement(_components.a, {
    href: "/hearing-aids/technology",
    className: "c-md-a"
  }, "The technology of modern digital hearing aids"), " offers many benefits that an amplifier does not have. Some of the features include:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/technology/bluetooth/",
    className: "c-md-a"
  }, "Bluetooth wireless connectivity")), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Connects to smartphones and other digital devices"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Automatic speech recognition"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Noise reduction Automatic adjustments to settings based on your environment"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Custom listening programs"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/tinnitus-masker",
    className: "c-md-a"
  }, "Tinnitus therapy")), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Ultra discreet design"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "When it comes to your hearing health, it is crucial that you get proper care. As seen on TV hearing aids may be the best bang for your buck, but it will not improve your quality of life. You need to be patient and do the necessary research to find the right device for you. When you decide to invest in better hearing, you are making a firm decision to enjoy life to the fullest. So, if an amplifier doesn’t do the full job, what is the alternative?"), "\n", React.createElement(ButtonCta, {
    copy: "Get the best hearing aid now",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "a-better-way-to-treat-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#a-better-way-to-treat-hearing-loss",
    "aria-label": "a better way to treat hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "A better way to treat hearing loss"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "At hear.com, your needs come first! We are not interested in selling you something you don’t need and can’t afford. On the contrary, we want to help you rejoin conversations, enjoy talking with friends at a picnic, and hear your grandchildren’s voices again. You can’t put a price on those precious moments. However, we understand that cost is always a factor when considering hearing aids. Our knowledgeable team of experts will crunch the numbers and find devices that fit your lifestyle — and budget. We can check your health insurance for hearing aid benefits, or offer you one of our monthly payment plans. Remember, cheaper is not always better, especially when the device will help you hear better and feel better. So don’t settle for as seen on TV hearing aids. Contact us today to learn more about the best hearing loss solutions available."), "\n", React.createElement(ButtonCta, {
    copy: "Get the best hearing aid now",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
